<template>
<!--  todo unused -->
<!-- Modal -->
  <div class="modal fade" id="searchCrew" tabindex="-1" role="dialog" aria-labelledby="searchCrewTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="searchCrewTitle">Search Captain / Master</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text" class="form-control form-control-sm mb-2" v-model="lastName" placeholder="search last name here">
          <div class=" table-responsive text-small" style="height: 500px;">
            <table class="table table-sm table-bordered table-hover text-uppercase text-center sticky-header">
              <thead>
                <tr>
                  <th class="e-bg-blue-gray-light-9">IPN</th>
                  <th class="e-bg-blue-gray-light-9">NAME</th>
                  <th class="e-bg-blue-gray-light-9">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="crew in crewKeys" :key="crew.id">
                  <td>{{crew.ipn}}</td>
                  <td>{{crew.last_name }} {{crew.extension}}, {{crew.first_name }},
                    {{crew.middle_name ? crew.middle_name.charAt(0) + '.' : ''}}</td>
                  <td>
                    <button class="e-btn e-btn-sm e-btn-blue">select</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'SearchCrew',
  data () {
    return {
      rankId: 1, // for capt/master,
      lastName: ''
    }
  },
  methods: {
    ...mapActions(['getCrewKeys']),
    searchCrews () {
      this.getCrewKeys({ rank_id: this.rankId })
    }
  },
  // created () {
  //
  // },
  computed: {
    ...mapGetters(['crewKeys'])
  },
  watch: {
    lastName: function (val) {
      this.lastName = val
      this.getCrewKeys({ rank_id: this.rankId, last_name: this.lastName })
      // console.log(this.searchField)
    }
  }
}
</script>

<style scoped>

</style>
